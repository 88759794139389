import React, { useState, useEffect } from "react";
import { SimpleCheckBox, TitleLayout } from "@temp/components";
import { PrimaryButton } from "@components";
import { useHistory } from "react-router-dom";
import { Images } from "@temp/assets";
import { DrawerAction } from "@temp/store/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "@temp/components/Loader";
import "./style.scss";
import { phoneNumberFormater, preventNonNumericalInput, showMessage } from "@temp/utils/helper";

export const AddReceipient = () => {
  const history = useHistory();
  const [search, setSearch] = useState("");
  const dispatch = useDispatch();
  const [selectedNetwork, setSelectedNetwork] = useState([]);
  const metaData = useSelector((state: RootState) => state?.Auth?.metaData);
  const mg_admin = metaData?.mg_recipient?.PHONE_NO;
  const all_contacts = useSelector((state: RootState) => state.Drawer.networkData) || [];

 

  useEffect(() => {
    getSearchedNetwork("");
  }, []);

  useEffect(() => {
    dispatch(DrawerAction.GetNetwork({ only_company_users: "" }));
  }, [])

  const selectNetwork = (item) => {
    setSelectedNetwork([item]);
  };

  const callback = (phoneNumber: any, id) => {
    if (phoneNumber) {
      history.replace("AddReceipientSuccessfully/" + phoneNumber, id);
    } else {
      history.replace("AddReceipientSuccessfully/" + phoneNumber, id);
    }
  };

  const addNetwork = () => {
    if (selectedNetwork[0]?.status !== "verified") {
      showMessage.current({
        message: "This user is unverified",
        status: "error"
      })
    } else {
      dispatch(
        DrawerAction.AddNetwork({
          selectedNetwork,
          cb: callback,
        })
      );
    }
  };

  const RegisterCard = ({ val, size = 6 }) => {
    return (
      <div data-size={size == 6 ? "50" : "100"} className={`recipentGridBox ${selectedNetwork.indexOf(val) !== -1 ? 'checked' : ''}`}>
        <div className="selectRecipentCheckBox">
          <SimpleCheckBox
            checked={selectedNetwork.indexOf(val) !== -1}
            handleChange={(e: any) => {
              if (all_contacts?.find(item => item?.networkUser?.phone_no == val?.phone_no)) {
                showMessage.current({
                  message:
                    "This user already exist in your network",
                  status: "error"
                })
              } else {
                selectNetwork(val);
              }
            }}
          />
        </div>
        <div className="insideRecipentContentBoxWrap">
          <img
            src={
              val?.picture || Images.myProfileImage
            }
            className="userImg"
          />
          <div className="w-100">
            {val?.company?.length ?
              <>
                {val?.company && val?.company.length ? (
                  <p className="companyTitle">
                    {val?.company && val?.company.length
                      ? val?.company[0]?.name || ""
                        ? val?.company[0]?.name || ""
                        : ""
                      : ""}
                  </p>
                ) : ""}
                {val?.name ? <p className="name">{val?.name || ""} </p> : ""}
                {val?.phone_no ? <p className="phone">{`${val?.phone_no !== mg_admin ? `${phoneNumberFormater(val?.phone_code, val?.phone_no)}` : " "} `}</p> : ""}
              </> :
              <>
                {val?.name ? <p className="companyTitle">{val?.name || ""}</p> : ""}
                {val?.company_name ? <p className="name">{val?.company_name}</p> : ""}
                {val?.phone_no !== mg_admin ? <p className="phone"> {`${val?.phone_no !== mg_admin ? `${phoneNumberFormater(val?.phone_code, val?.phone_no)}` : " "} `}</p> : ""}
              </>
            }
          </div>
        </div>
      </div>
    );
  };

  const getSearchedNetwork = (params) => {
    dispatch(DrawerAction.SearchNetwork(params));
  };

  const networkData = useSelector((state: RootState) => state.Drawer.searchNetworkData);

  const searchNetwork = (event) => {
    if (event.target.value.length == 11) {
      getSearchedNetwork(event.target.value.replace(/^0+/, ""));
    } else if (event.target.value.length > 10) {
      return;
    } else if (event.target.value === "") {
      getSearchedNetwork("");
      setSelectedNetwork([]);
    }
    setSearch(event.target.value);
  };

  return (
    <TitleLayout titleOnly titleOnlyText="Add Receipient">
      <div className="addRecipentWrapper">
        <div className={`searchContainer ${search ? "filled" : ""}`}>
          <input
            className="searchInput"
            name="search"
            onKeyPress={(event) => preventNonNumericalInput(event)}
            inputMode="numeric"
            pattern="\d*"
            style={{ width: "-webkit-fill-available" }}
            type="number"
            placeholder="Search by number eg: 03123456789"
            value={search}
            onChange={searchNetwork}
          />
          <span className={`icons-search ${search ? '' : 'active'}`}></span>
          <span
            className={`icons-cross ${search ? 'active' : ''}`}
            onClick={() => {
              setSearch("");
              setSelectedNetwork([]);
              getSearchedNetwork("");
            }}
          ></span>
        </div>

        <div className="recipentGridWrapper">
          <div className="recipentGridBoxWrap">
            {networkData &&
              !!networkData.length &&
              networkData.map((val: any) => <RegisterCard val={val} />)}
          </div>
        </div>

        <div className="receipient-post-btn">
          <PrimaryButton
            disabled={!selectedNetwork.length}
            title="Add to Network"
            onClick={() => {
              addNetwork();
            }}
          />
        </div>
      </div>
    </TitleLayout>
  );
};