import { Images } from "@temp/assets";

// Drawer items data

export const LOAD_TYPE = [null, "FCL", "LCL", "BULK"];
export const drawerData = [
  {
    name: "My Profile",
    inactiveIcon: Images.UserIconInactive,
    activeIcon: Images.UserIconActive,
    iconClass: 'icons-profile'
  },
  {
    name: "My Contacts",
    inactiveIcon: Images.NetworkInactive,
    activeIcon: Images.NetworkActive,
    iconClass: 'icons-network'
  },
  {
    name: "My Vehicles",
    inactiveIcon: Images.VehicleInactive,
    activeIcon: Images.VehicleActive,
    iconClass: 'icons-vehicle'
  },
  {
    name: "Saved Locations",
    inactiveIcon: Images.LocationInactive,
    activeIcon: Images.LocationActive,
    iconClass: 'icons-location'
  },
  {
    name: "Awarded Contracts",
    inactiveIcon: Images.AwardContractInActive,
    activeIcon: Images.AwardContractActive,
    iconClass: "icons-awarded-contract"
  },
  {
    name: "Draft Loads",
    inactiveIcon: Images.DraftInactive,
    activeIcon: Images.DraftActive,
    iconClass: 'icons-draft'
  },
  // {
  //   name: "Promotions",
  //   iconClass: 'icons-promo'
  // },

  {
    name: "Dashboard & Reports",
    iconClass: 'icons-report'
  },
  // {
  //   name: "Account Balance",
  //   inactiveIcon: Images.AccountInactive,
  //   activeIcon: Images.AccountActive,
  //   iconClass: 'icons-account'
  // },
  {
    name: "Help",
    inactiveIcon: Images.HelpInactive,
    activeIcon: Images.HelpActive,
    iconClass: 'icons-help'
  },
  {
    name: "Terms and Conditions",
    inactiveIcon: Images.TermsInactive,
    activeIcon: Images.TermsActive,
    iconClass: 'icons-terms'
  },

  {
    name: "Logout",
    inactiveIcon: Images.LogoutInactive,
    activeIcon: Images.LogoutActive,
    iconClass: 'icons-logout'
  },
];

// Network screen dummy data
export const networkDummyData = [
  {
    name: "Ed Revalo 1",
    profile_image: "",
    company_name: "Huawei",
    alias_name: "Ed 1",
    phone_number: "1233333",
  },
  {
    name: "Ed Revalo 2",
    profile_image: "",
    company_name: "Xiaomi",
    alias_name: "Ed 2",
    phone_number: "123444444",
  },
  {
    name: "Ed Revalo 3",
    profile_image: "",
    company_name: "Oppo",
    alias_name: "Ed 3",
    phone_number: "1235555",
  },
  {
    name: "Ed Revalo 4",
    profile_image: "",
    company_name: "Samsung",
    alias_name: "Ed 4",
    phone_number: "12366666",
  },
  {
    name: "Ed Revalo 5",
    profile_image: "",
    company_name: "LG",
    alias_name: "Ed 5",
    phone_number: "12367777",
  },
  {
    name: "Ed Revalo 6",
    profile_image: "",
    company_name: "Google",
    alias_name: "Ed 6",
    phone_number: "1236888",
  },
];

// Dashboard screen dummy data
export const loadsDmmyData = [
  {
    id: 1,
    load_id: "Load ID - 01",
    name: "load one",
    type: "FCL",
    transaction_type: "offer",
    load_from: " Ahmed Ali",
    response: [
      {
        id: 1,
        name: "category 1",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 2,
        name: "category 2",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 3,
        name: "category 3",
        total_responder: 12,
        total_response: 3,
      },
    ],
    awarded: [
      {
        id: 1,
        name: "category 1",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 2,
        name: "category 2",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 3,
        name: "category 3",
        total_responder: 12,
        total_response: 3,
      },
    ],
  },
  {
    id: 2,
    load_id: "Load ID - 02",
    name: "load two",
    load_from: " Ahmed Ali",
    type: "FCL",
    transaction_type: "bid",
    response: [
      {
        id: 1,
        name: "cat one",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 2,
        name: "cat two",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 3,
        name: "cat three",
        total_responder: 12,
        total_response: 3,
      },
    ],
    awarded: [],
  },
  {
    id: 2,
    load_id: "Load ID - 02",
    name: "load two",
    type: "FCL",
    transaction_type: "bid",
    load_from: " Ahmed Ali",
    response: [
      {
        id: 1,
        name: "cat one",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 2,
        name: "cat two",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 3,
        name: "cat three",
        total_responder: 12,
        total_response: 3,
      },
    ],
    awarded: [],
  },
  {
    id: 2,
    load_id: "Load ID - 02",
    name: "load two",
    type: "FCL",
    transaction_type: "bid",
    load_from: " Ahmed Ali",
    response: [
      {
        id: 1,
        name: "cat one",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 2,
        name: "cat two",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 3,
        name: "cat three",
        total_responder: 12,
        total_response: 3,
      },
    ],
    awarded: [],
  },
  {
    id: 2,
    load_id: "Load ID - 02",
    name: "load two",
    type: "FCL",
    transaction_type: "bid",
    load_from: " Ahmed Ali",
    response: [
      {
        id: 1,
        name: "cat one",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 2,
        name: "cat two",
        total_responder: 12,
        total_response: 3,
      },
      {
        id: 3,
        name: "cat three",
        total_responder: 12,
        total_response: 3,
      },
    ],
    awarded: [],
  },
];

export const newLoadsDummyData = [
  {
    id: 1,
    load_id: "Load ID - 01",
    name: "load one",
    type: "FCL",
    transaction_type: "offer",
    load_from: " Ahmed Ali",
    response: [
      {
        id: 1,
        name: "category 1",
        total_responder: 6,
        total_response: 0,
      },
      {
        id: 2,
        name: "category 2",
        total_responder: 12,
        total_response: 0,
      },
      {
        id: 3,
        name: "category 3",
        total_responder: 5,
        total_response: 0,
      },
      {
        id: 3,
        name: "category 3",
        total_responder: 5,
        total_response: 0,
      },
    ],
    awarded: [],
  },
];

// Chat screen dummy data
export const chatListDummyData = [
  {
    user: {
      name: "Jalal Uddin",
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
    message: "Hello, this is dummy text message",
    message_count: 3,
    message_time: "20:30",
  },
  {
    user: {
      name: "Abid Ali",
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
    message: "Hello, this is dummy text message",
    message_count: 0,
    message_time: "20:30",
  },
  {
    user: {
      name: "Sami Ullah",
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
    message: "Hello, this is dummy text message",
    message_count: 0,
    message_time: "12:38",
  },
];

export const messagesDummyData = [
  {
    id: 1,
    message: {
      author: "jalal",
      body: "this is my message",
      dateCreated: "20:50",
      user_id: 1,
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
  {
    id: 1,
    message: {
      author: "jalal",
      body: "this is other message",
      dateCreated: "20:50",
      user_id: 2,
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
  {
    id: 1,
    message: {
      author: "jalal",
      dateCreated: "20:50",
      user_id: 3,
      media_imge: "https://i.ytimg.com/vi/DEsQs2jLEOg/maxresdefault.jpg",
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
  {
    id: 1,
    message: {
      author: "jalal",
      media_imge: "https://i.ytimg.com/vi/DEsQs2jLEOg/maxresdefault.jpg",
      dateCreated: "20:50",
      user_id: 1,
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
  {
    id: 1,
    message: {
      author: "jalal",
      body: "this is message",
      dateCreated: "20:50",
      user_id: 1,
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
  {
    id: 1,
    message: {
      author: "jalal",
      body: "this is message",
      dateCreated: "20:50",
      user_id: 1,
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
  {
    id: 1,
    message: {
      author: "jalal",
      body: "this is message",
      dateCreated: "20:50",
      user_id: 1,
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
  {
    id: 1,
    message: {
      author: "jalal",
      body: "this is message",
      dateCreated: "20:50",
      user_id: 1,
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
  {
    id: 1,
    message: {
      author: "jalal",
      body:
        "this is message last jdksfj jsdf jklds fklsd k fkl fkl fjk jfsdkl klsd fskld sdkf sdklf sdkf jsdklf  sdfjkl",
      dateCreated: "20:50",
      user_id: 5,
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
  {
    id: 1,
    message: {
      author: "jalal",
      body:
        "this is message last jdksfj jsdf jklds fklsd k fkl fkl fjk jfsdkl klsd fskld sdkf sdklf sdkf jsdklf  sdfjkl",
      dateCreated: "20:50",
      user_id: 1,
      profile_img:
        "https://image.shutterstock.com/image-vector/default-avatar-profile-icon-grey-260nw-518740741.jpg",
    },
  },
];

// account balance dummy data
export const accountBalanceDummyData = [
  {
    ChargeType: "Success Fee Charge",
    date: "21/01/2022",
    percentage: "2%",
    loadID: 123456,
    price: "Rs.500",
    priceType: "profit",
    name: "Jalal uddin",
    profile:
      "https://ps.w.org/simple-user-avatar/assets/icon-256x256.png?rev=2413146",
  },
  {
    ChargeType: "Payments Recieved",
    date: "21/01/2022",
    percentage: "2%",
    loadID: 123456,
    price: "Rs.500",
    priceType: "loss",
    name: "Jalal uddin",
    profile:
      "https://ps.w.org/simple-user-avatar/assets/icon-256x256.png?rev=2413146",
  },
];

// saved locations dummy data

export const savedLocationsDummyData = [
  {
    Company_Name: "Dilkusha Forum",
    Company_Address: "14th floor- Tariq Road, Karachi-Sindh",
  },
  {
    Company_Name: "Saddar town",
    Company_Address: "7550 SW 128th St New Richland, Minnesota(MN)...",
  },
  {
    Company_Name: "Dilkusha Forum",
    Company_Address: "14th floor- Tariq Road, Karachi-Sindh",
  },
  {
    Company_Name: "Dilkusha Forum",
    Company_Address: "14th floor- Tariq Road, Karachi-Sindh",
  },
];

// vehicled dummy data
export const vehiclesDummyData = [
  {
    Number_plate: "ABC-123",
    Vehicle_type: "20 ft Truck",
    Driver_name: "Driver Name",
    Mobile_number: "03001234567",
  },
  {
    Number_plate: "ABC-123",
    Vehicle_type: "20 ft Truck",
    Driver_name: "Driver Name",
    Mobile_number: "03001234567",
  },
  {
    Number_plate: "ABC-123",
    Vehicle_type: "20 ft Truck",
    Driver_name: "Driver Name",
    Mobile_number: "03001234567",
  },
  {
    Number_plate: "ABC-123",
    Vehicle_type: "20 ft Truck",
    Driver_name: "Driver Name",
    Mobile_number: "03001234567",
  },
];

// notifications dummy data
export const notificationsDummyData = [
  {
    PendingContainerReference: "Pending Container Reference",
    load2: "Load2",
    timeStamp: "Jul 23, 2021 at 09:15 AM",
  },
  {
    PendingContainerReference: "Driver has started transporting",
    load2: "Load5",
    timeStamp: "Jul 23, 2021 at 09:15 AM",
  },

  {
    PendingContainerReference: "Your bid has been Declined",
    load2: "Load2",
    timeStamp: "Jul 23, 2021 at 09:15 AM",
  },
  {
    PendingContainerReference: "Great news! your bid has been Accepted",
    load2: "Load2",
    timeStamp: "Jul 23, 2021 at 09:15 AM",
  },
];

export const draftLoadDummyData = [
  {
    id: 1,
    load_id: "Load ID- 012345",
    type: "FCL",
    transaction_type: "offer",
    load_name: "Upcountry Load",
    Category_1: "20 FB (25 Tons)",
    Category_2: "40 FB (15 Tons)",
    quantity_of_category_1: 2,
    quantity_of_category_2: 1,
  },
  {
    id: 2,
    load_id: "Load ID - 024680",
    Quote_Type: "Vehicle",
    type: "LCL",
    transaction_type: "offer",
    load_name: "LCL Load",
    Category_1: "20 Ft Truck",
    Category_2: "45 Ft Trailer (15 Tons)",
  },
  {
    id: 3,
    load_id: "Load ID - 024681",
    Quote_Type: "Weight /Volume",
    type: "BULK",
    transaction_type: "Bid",
    load_name: "BULK Load",
    Total_Freight_Ton: "12354.89 F.Ton",
    Num_of_Packages: "45 Ft Trailer (15 Tons)",
  },
];
